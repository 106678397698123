<template>

  <div class="text-center">
    <div style="padding-top: 100px">
      <router-link to="/"><img src="../assets/logo.png" class="logo me-3"></router-link>
      <h1><b>谐调中医</b></h1>
    </div>
  </div>
  <div class="row text-center">
    <div class="col-12 my-3">
      <a href="https://mzbl.xietiao.com" class="btn btn-lg btn-outline-success">谐调中医诊疗系统</a>
    </div>
    <div class="col-12 my-3">
      <a href="https://zyf.xietiao.com" class="btn btn-lg btn-outline-success">谐调中医药查询系统</a>
    </div>
    <div class="col-12 my-3">
      <a href="https://zy.xietiao.com" class="btn btn-lg btn-outline-success">河洛中医</a>
    </div>
  </div>

</template>
